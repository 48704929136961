import React, { useRef, useState } from "react";

import { Button, Img, Input, Text } from "components";
import { Link } from "react-router-dom";
import "../styles/index.css";
import "../styles/tailwind.css";

const scriptUrl =
  "https://script.google.com/macros/s/AKfycbxxUgTLVxk8JcqWL9LaipLsjGjJFHX_crZAyBS04pJpplRdWvD8gHQboXqVuKs6Ijw-Vg/exec";

const SuccessAlert = ({
  waitlistSuccessMsgShown,
  setWaitlistSuccessMsgShown,
}) => {
  return (
    <div
      className="absolute bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded"
      role="alert"
      style={{
        width: "50%",
        left: "50%",
        transform: "translateX(-50%)",
        top: "5px",
        display: waitlistSuccessMsgShown ? "block" : "none",
      }}
    >
      <span className="block sm:inline">
        <b>Thank you!</b> You’ve been added to the waitlist.
      </span>
      <span
        className="absolute top-0 bottom-0 right-0 px-4 py-3"
        onClick={() => {
          setWaitlistSuccessMsgShown(false);
        }}
      >
        <svg
          className="fill-current h-6 w-6 text-red-500"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    </div>
  );
};

const DesktopFourPage = () => {
  const [waitlistSuccessMsgShown, setWaitlistSuccessMsgShown] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [waitListEmails, setWaitListEmails] = useState(() => {
    const saved = localStorage.getItem("waitListEmails");
    const initialValue = JSON.parse(saved);
    return initialValue || [];
  });
  const addEmailToWaitList = (email) => {
    if (!waitListEmails.includes(email)) {
      const newWaitListEmails = [...waitListEmails, email];
      setWaitListEmails(newWaitListEmails);
      localStorage.setItem("waitListEmails", JSON.stringify(newWaitListEmails));
      return true;
    } else {
      return false;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    if (addEmailToWaitList(formData.get("emailinput"))) {
      setProcessing(true);
      fetch(scriptUrl, { method: "POST", body: new FormData(e.target) })
        .then((res) => {
          // console.log("SUCCESSFULLY SUBMITTED");
          setWaitlistSuccessMsgShown(true);
          setProcessing(false);
          setTimeout(() => {
            setWaitlistSuccessMsgShown(false);
          }, 3000);
        })
        .catch((err) => console.log(err));
    } else {
      alert("Email already added to waitlist");
    }
  };
  return (
    <>
      <SuccessAlert
        waitlistSuccessMsgShown={waitlistSuccessMsgShown}
        setWaitlistSuccessMsgShown={setWaitlistSuccessMsgShown}
      />
      <div className="bg-white-A700 flex flex-col font-avenirnext items-start justify-start mx-auto w-full">
        <div className="flex flex-col items-center w-full">
          <div className="bg-yellow-50 flex flex-col items-center justify-start p-[49px] md:px-10 sm:px-5 w-full">
            <div className="flex flex-col items-center justify-start max-w-[1820px] mb-[26px] mx-auto w-full">
              <div className="flex xs:flex-col flex-row font-avenirnext md:gap-10 items-start justify-between w-full">
                <Img
                  className="md:h-auto object-contain"
                  src="images/beena_suggests_logo.png"
                  alt="groupFive"
                />
                <Button
                  onClick={() => {
                    window.open("https://demo.beenasuggests.com", "_blank");
                  }}
                  className="cursor-pointer font-semibold leading-[normal] min-w-[162px] text-base text-center"
                  style={{ backgroundColor: "rgba(230, 24, 97, 1)" }}
                >
                  Try It Now
                </Button>
              </div>
              <Text
                className="mt-[104px] md:text-5xl text-6xl text-center text-purple-900_01  w-[61%] 2xl:w-full"
                size="txtAvenirNextBold60"
              >
                Visual Search and Product Discovery for Home Decor Businesses
              </Text>
              <Text
                className="leading-[40.00px] mt-[59px] text-2xl md:text-[22px] text-black-900 text-center sm:text-xl w-[45%] 2xl:w-full"
                size="txtNunitoRegular24"
              >
                Help shoppers find what they are looking for visually with image
                search and visually similar product recommendations
              </Text>
              <form
                onSubmit={handleSubmit}
                className="flex md:flex-col flex-row font-avenirnext gap-3 items-center justify-center mt-[59px] w-[35%] md:w-full"
              >
                <Input
                  name="emailinput"
                  placeholder="Your Business Email"
                  className="font-semibold leading-[normal] p-0 placeholder:text-black-900 text-base text-left w-full"
                  wrapClassName="w-[73%] md:w-full"
                  type="email"
                  required={true}
                />
                <Button
                  type="submit"
                  className="capitalize cursor-pointer font-semibold leading-[normal] min-w-[162px] text-base text-center"
                  color="purple_900_01"
                >
                  Join Waitlist &nbsp;
                  {processing ? <i class="fa fa-spinner fa-spin"></i> : ""}
                </Button>
              </form>
            </div>
          </div>
          <div className="flex flex-col justify-start max-w-[1534px] mt-[61px] mx-auto px-5 w-full">
            <div className="flex flex-col items-center justify-start md:ml-[0] w-[100%] md:w-full">
              <div className="md:h-[55px] h-[75px] relative w-full">
                <Text
                  className="inset-x-[0] mx-auto sm:text-4xl md:text-[38px] text-[40px] text-center text-pink-500 top-[0] uppercase w-max"
                  size="txtAvenirNextDemiBold40"
                >
                  Why Beena Suggests?
                </Text>
              </div>
            </div>
            <div className="flex md:flex-col-reverse flex-row font-nunito md:gap-10 items-center justify-between md:ml-[0] mt-10 items-right md:w-full">
              <Text
                className="leading-[40.00px] text-2xl md:text-[22px] text-black-900 sm:text-xl md:w-full"
                size="txtNunitoRegular24"
              >
                If the only way shoppers can search for a product is through
                text search, you are adding friction by forcing them to describe
                their visual idea to words. As a result, they might not find the
                product even though it is available on your website.
              </Text>
              <Img
                className="w-[50%] md:h-auto object-contain"
                src="images/img_group5.png"
                alt="groupFive"
              />
            </div>
            <div className="flex md:flex-col flex-row gap-[17px] inset-x-[0] items-center justify-between mx-auto top-[0] w-full md:w-full mt-10">
              <Img
                className="w-[60%] md:h-auto object-cover"
                src="images/img_group171.png"
                alt="group171"
              />
              <Text
                className="leading-[40.00px] text-2xl md:text-[22px] text-black-900 text-right sm:text-xl  md:w-full"
                size="txtNunitoRegular24"
              >
                Beena Suggests allows you to make it easy for such shoppers to
                find products they are looking for by doing an image search.
              </Text>
            </div>
            <div className="bottom-[0] flex md:flex-col-reverse flex-row md:gap-10 gap-[61px] items-start justify-start md:w-full">
              <Text
                className="leading-[40.00px] md:mt-0 mt-[117px] text-2xl md:text-[22px] text-black-900 sm:text-xl w-[59%] sm:w-full md:w-full"
                size="txtNunitoRegular24"
              >
                Increase revenue per user, order size and conversions with not
                only visual search but also by recommending visually similar
                products to shoppers in as little as 10 minutes.
              </Text>
              <Img
                className="w-[40%] sm:h-auto object-contain w-[36%] md:w-full"
                src="images/img_bargraph1.png"
                alt="bargraphOne"
              />
            </div>
          </div>
          <div className="bg-yellow-50 flex md:flex-col flex-row md:gap-5 items-start justify-end mt-[110px] p-[10px] md:px-10 sm:px-5 w-full">
            <div className="flex md:flex-1 flex-col font-nunito gap-[26px] items-center justify-start mb-3 md:mt-0 mt-[80px] w-full">
              <Text
                className="md:ml-[0] sm:text-4xl md:text-[38px] text-[40px] text-center text-pink-500 uppercase"
                size="txtAvenirNextDemiBold40"
              >
                Our Solutions
              </Text>
              <div className="flex md:flex-col flex-row items-center justify-start w-[70%] 2xl:w-full">
                <div className="flex md:flex-1 flex-col font-nunito gap-[26px] items-center justify-start mb-3 md:ml-[0] md:mr-0 md:mt-0 mt-[20px] w-full">
                  <Img
                    className="h-[150px] md:h-auto object-cover w-[150px]"
                    src="images/img_searchimage1.png"
                    alt="searchimageOne"
                  />
                  <Text
                    className="leading-[40.00px] text-2xl md:text-[22px] text-black-900 text-center sm:text-xl"
                    size="txtNunitoRegular24"
                  >
                    <span className="text-black-900 font-nunito font-bold">
                      <>
                        Image Search
                        <br />
                      </>
                    </span>
                    <span className="text-black-900 font-nunito font-normal">
                      <>
                        <br />
                        Shoppers upload images they want to find items similar
                        to. Our AI technology identifies what is in the image,
                        matches that to your product catalogue and shows
                        relevant results to the shopper.
                      </>
                    </span>
                  </Text>
                </div>
                <div className="flex md:flex-1 flex-col font-nunito gap-[26px] items-center justify-start mb-3 md:ml-[0]  md:mt-0 md:mr-0 mt-[20px] w-full">
                  <Img
                    className="h-[150px] md:h-auto object-contain w-[150px]"
                    src="images/img_recommendation1.png"
                    alt="recommendationOne"
                  />
                  <Text
                    className="leading-[40.00px] text-2xl md:text-[22px] text-black-900 text-center sm:text-xl"
                    size="txtNunitoRegular24"
                  >
                    <span className="text-black-900 font-nunito font-bold">
                      <>
                        Visual Product Recommendation
                        <br />
                      </>
                    </span>
                    <span className="text-black-900 font-nunito font-normal">
                      <>
                        <br />
                        Shoppers will see visually similar products as a
                        recommendation below your product information fold, in
                        addition to other “You may also like” recommendations.
                        <br />
                      </>
                    </span>
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end mt-[92px] md:px-10 sm:px-5 px-[72px] w-full">
          <div className="flex flex-col items-center justify-start w-[92%] md:w-full">
            <div className="flex flex-col gap-[38px] justify-start w-full">
              <div className="md:h-[55px] h-[66px] md:ml-[0] relative md:w-full text-center justify-center">
                <Text
                  className="sm:text-4xl md:text-[38px] text-[40px] text-center text-pink-500 top-[0] uppercase"
                  size="txtAvenirNextDemiBold40"
                >
                  What’s Under the Hood?
                </Text>
              </div>
              <div className="flex xl:flex-col flex-row font-nunito md:gap-10 items-start justify-between w-full">
                <Text
                  className="leading-[40.00px] sm:mt-0 mt-10 text-2xl md:text-[22px] text-black-900 sm:text-xl"
                  size="txtNunitoRegular24"
                >
                  Our proprietary technology makes use of textual information in
                  addition to the images available to show the right products
                  when doing an image search or showing visually similar
                  products. We generate rich tags of your products automatically
                  using our AI technology trained specifically to the Home
                  Furnishings industry to enhance search and product
                  recommendation quality.
                </Text>
                <Img
                  className="h-[717px] md:h-auto object-cover"
                  src="images/img_wuthimg.png"
                  alt="wuthimg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center mt-[114px] w-full">
          <div className="bg-gray-50 flex flex-col items-center justify-start p-[86px] md:px-10 sm:px-5 w-full">
            <Text
              className="sm:text-4xl md:text-[38px] text-[40px] text-center text-pink-500 uppercase"
              size="txtAvenirNextDemiBold40"
            >
              AVAILABLE PLATFORMS
            </Text>
            <Text
              className="mt-[61px] text-2xl md:text-[22px] text-black-900 text-center sm:text-xl"
              size="txtNunitoRegular24"
            >
              Beena Suggests can be easily integrated on your website,
              regardless of what eCommerce platform you use or if it is a custom
              built website.
            </Text>
            <div className="flex md:flex-col flex-row font-nunito gap-10 items-center md:items-center justify-center max-w-[1552px] mb-3.5 mt-[109px] mx-auto w-full">
              <Img
                className="sm:flex-1 h-[59px] md:h-auto sm:mt-0 object-contain w-[13%] sm:w-full"
                src="images/img_2560pxshopifylogo.png"
                alt="2560pxshopifylo"
              />
              <Img
                className="sm:flex-1 h-[60px] md:h-auto sm:ml-[0]  sm:mt-0 mt-[11px] object-contain w-[7%] sm:w-full"
                src="images/img_woocommercelogo.png"
                alt="woocommercelogo"
              />
              <Img
                className="sm:flex-1 h-[60px] md:h-auto mb-[11px] sm:ml-[0] object-contain w-[18%] sm:w-full"
                src="images/img_bigcommercelogodark.png"
                alt="bigcommercelogo"
              />
              <Img
                className="sm:flex-1 h-[60px] md:h-auto mb-1 sm:ml-[0]  sm:mt-0 mt-[7px] object-contain w-[14%] sm:w-full"
                src="images/img_2560pxmagento1.png"
                alt="2560pxmagentoOne"
              />
              <Text
                className="capitalize sm:ml-[0] sm:mt-0 mt-[7px] md:text-3xl sm:text-[28px] text-[32px] text-center text-purple-900"
                size="txtNunitoSemiBold32"
              >
                Custom Website
              </Text>
            </div>
          </div>
          <div className="flex flex-col gap-[52px] items-center justify-start max-w-[1405px] mt-20 mx-auto md:px-5 w-full">
            <div className="md:h-[55px] h-[66px] relative w-[13%]">
              <Text
                className="absolute left-[0] sm:text-4xl md:text-[38px] text-[40px] text-center text-pink-500 top-[0] uppercase"
                size="txtAvenirNextDemiBold40"
              >
                PRICING
              </Text>
            </div>
            <div className="font-nunito md:h-[100%] h-[373px] sm:h-[516px] relative w-full">
              <Text
                className="inset-x-[0] leading-[40.00px] mx-auto text-2xl md:text-[22px] text-black-900 text-center sm:text-xl top-[0] w-[90%] sm:w-full"
                size="txtNunitoRegular24"
              >
                Our product is currently available to limited customers only. We
                will update our pricing once it is available to everyone.
              </Text>
              <div className="bottom-[0] flex md:flex-col flex-row gap-10 inset-x-[0] items-center justify-between mx-auto w-full mt-10">
                <Text
                  className="leading-[40.00px] mb-1.5 text-2xl md:text-[22px] text-black-900 text-center sm:text-xl"
                  size="txtNunitoRegular24"
                >
                  <>
                    <b>Pay-as-you-go</b>
                    <br />
                    For stores with limited SKUs and sales, to make it easy for
                    you to use our technology. All of our features are available
                    in this plan and will always be available. We won’t ever
                    hide our best features under expensive subscription tiers,
                    ever!
                  </>
                </Text>
                <Text
                  className="leading-[40.00px] sm:mt-0 mt-1.5 text-2xl md:text-[22px] text-black-900 text-center sm:text-xl"
                  size="txtNunitoRegular24"
                >
                  <>
                    <b>Subscription</b>
                    <br />
                    For stores with lots of SKUs and sales. Pay-as-you-go plans
                    are good for starting out but tend to get expensive with
                    higher volume. You pay a monthly fee based on your website
                    traffic.
                  </>
                </Text>
              </div>
            </div>
          </div>
          <div className="h-[547px] sm:h-[400px] md:h-[400px] mt-[117px] md:px-5 relative w-full">
            <div className="bg-gray-50 h-[545px] m-auto w-full"></div>
            <div
              className="absolute bg-100  md:bg-auto bg-center bg-no-repeat flex flex-col h-full inset-[0] items-center justify-center m-auto p-[67px] md:px-10 sm:px-5 w-full"
              style={{ backgroundImage: "url('images/img_group1.png')" }}
            >
              <div className="flex flex-col md:gap-10 gap-[82px] items-center justify-start mb-[129px] w-[57%] xl:w-full">
                <Text
                  className="capitalize md:text-4xl text-6xl text-center text-white-A700 w-full"
                  size="txtAvenirNextDemiBold60"
                >
                  Visual Search and Product Discovery Solutions
                </Text>
                <form
                  onSubmit={handleSubmit}
                  className="flex md:flex-col flex-row gap-5 items-center justify-center w-[64%] md:w-full"
                >
                  <Input
                    name="emailinput"
                    placeholder="Your Business Email"
                    className="font-semibold leading-[normal] p-0 placeholder:text-black-900 text-base text-left w-full"
                    wrapClassName="w-[72%] md:w-full"
                    type="email"
                    required={true}
                  ></Input>
                  <Button
                    className="capitalize cursor-pointer font-semibold leading-[normal] min-w-[162px] text-base text-center"
                    color="pink_500"
                  >
                    Join Waitlist
                    {processing ? <i class="fa fa-spinner fa-spin"></i> : ""}
                  </Button>
                </form>
              </div>
            </div>
          </div>
          <footer
            className="bg-gray-50 flex items-center justify-center md:px-5 w-full"
            style={{ marginTop: "-2px", justifyContent: "right" }}
          >
            <div className="flex sm:flex-col flex-row gap-10 items-start justify-between  mr-[17px] my-[39px]">
              <div className="flex flex-col gap-[15px] items-start justify-start">
                <a
                  href="javascript:"
                  className="text-2xl md:text-[22px] text-pink-500 sm:text-xl uppercase"
                >
                  <Text size="txtAvenirNextDemiBold24">contact us</Text>
                </a>
                <Text
                  className="leading-[40.00px] text-2xl md:text-[22px] text-black-900 sm:text-xl"
                  size="txtNunitoBold24"
                >
                  <>
                    sales@beenasuggests.com
                    <br />
                    <br />
                    100 Signal Hill Rd
                    <br />
                    St. John’s, NL
                    <br />
                    A1A 1B3
                  </>
                </Text>
              </div>
              <div className="flex flex-col gap-[15px] items-start justify-start">
                <Text
                  className="text-2xl md:text-[22px] text-pink-500 sm:text-xl uppercase"
                  size="txtAvenirNextDemiBold24"
                >
                  Legal
                </Text>
                <Text
                  className="leading-[40.00px] text-2xl md:text-[22px] text-black-900 sm:text-xl"
                  size="txtNunitoBold24"
                >
                  <>
                    <Link to={"/privacypolicy"}>Privacy Policy</Link>
                    <br />
                    <Link to={"/cookiepolicy"}>Cookie Policy</Link>
                  </>
                </Text>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default DesktopFourPage;
