import React from "react";

const sizeClasses = {
  txtAvenirNextDemiBold60: "font-avenirnext font-semibold",
  txtAvenirNextBold60: "font-avenirnext font-bold",
  txtNunitoBold24: "font-bold font-nunito",
  txtNunitoSemiBold32: "font-nunito font-semibold",
  txtNunitoRegular24: "font-normal font-nunito",
  txtAvenirNextDemiBold24: "font-avenirnext font-semibold",
  txtAvenirNextBold24: "font-avenirnext font-bold",
  txtAvenirNextDemiBold40: "font-avenirnext font-semibold",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
